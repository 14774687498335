import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage, { notify_info, notify_success, toastStyle, toatStyles } from './pages/LoginPage';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { Breadcrumb, Dropdown, Layout, Menu, Space, theme } from 'antd';
import { BoxPlotOutlined, DashboardOutlined, DownOutlined, LogoutOutlined, TeamOutlined, UngroupOutlined, UserOutlined,  } from '@ant-design/icons';
import UsersPage from './pages/UsersPage';
import AdminUsersPage from './pages/AdminUsersPage';
import DashboardPage from './pages/DashboardPage';
import { ToastContainer} from 'react-toastify';
import FactsPage from './pages/FactsPage';
import LabelsPage from './pages/LabelsPage';
import { Store } from 'redux';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectAuthenticated, selectUserDetails, selectLoggedIn, selectAuthMessage } from './features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { authorizeUser, logoutUser } from './features/auth/authAsyncThunk';
import PlantsPage from './pages/PlantsPage';
import { io } from 'socket.io-client';
import { API_SOCKET } from './constants';
const socket = io(API_SOCKET);

const { Header, Content, Footer, Sider } = Layout;

<ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
const items: MenuItem[] = [
  getItem('Dashboard', '1', <DashboardOutlined />),
  getItem('Data', '2', <UngroupOutlined />, [getItem('Data', '3')]),
  getItem('Plants', '4', <BoxPlotOutlined /> ),
  getItem('Users', '5', <TeamOutlined />),

];

const user_items: MenuItem[] = [
  getItem('Dashboard', '1', <DashboardOutlined />),
  getItem('Data', '2', <UngroupOutlined />, [getItem('Data', '3')]),
  getItem('Plants', '4', <BoxPlotOutlined /> ),

];

interface AppProps{
  store: Store
}

const  App: React.FC<AppProps> = ({store})=> {
    const authenticated = useSelector(selectAuthenticated);
    const loggedIn = useSelector(selectLoggedIn)
    const loginMessage = useSelector(selectAuthMessage)
    const userDetails = useSelector(selectUserDetails)
    const user_details = useSelector(selectUserDetails)
     const user_plants = user_details?.plant

    const admin = userDetails?.role === 'admin'
    let final_items = admin ? items : user_items
  const dispatch = useDispatch()

    useEffect(()=>{
      dispatch(authorizeUser() as any)
  }, [])

  const [collapsed, setCollapsed] = useState(false);
  const [selectClientUsersPage, setSelectClientUsersPage] = useState(true);
  const [selectedPage, setSelectedPage] = useState( 1); 
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleNavClick = (e: any) => {
    setSelectedPage(e.key);

  };

    const handleLogout = () => {
    dispatch(logoutUser() as any)
    notify_success(loginMessage, toastStyle)
    
  }

    const itemsNew = [
    { key: "1",label: "Profile", icon: <UserOutlined /> },
    { key: "2", danger: true,  label: "Logout", icon: <LogoutOutlined/>, onClick: handleLogout},
  ];

  useEffect(()=>{

    socket.on('plantConfigurationsChanged', (plant: any) => {
            if(user_plants?.includes(plant._id)){

            notify_info(`${plant.name} configurations have been updated`, toatStyles)
            }
        });

        return () => {
            socket.off('plantConfigurationsChanged');
        };


  }, [])

  return (
    
    <BrowserRouter>
    <ToastContainer position="top-center" className='text-capitalize small'/>
    {(loggedIn || authenticated) && ( <Layout style={{ minHeight: '100vh' }} hasSider>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="demo-logo-vertical" />
        <Menu   onClick={(e) => {
            handleNavClick(e)
          }} theme="dark" defaultSelectedKeys={selectClientUsersPage ? ['8'] : ['6']} mode="inline" items={ final_items}/>
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
        <Row style={{width: '100%'}}>
        <Col></Col>

        <Col className='d-flex justify-content-end px-2'>
        <Dropdown
          menu={{
            items:itemsNew,
          }}
        >
          
            <Space className='link-primary pre-auto'>
              <span>{userDetails?.other_names}</span>
              <span>{userDetails?.surname}</span>
              <DownOutlined />
            </Space>
          
        </Dropdown>
        </Col>
        </Row>
        </Header>
        <Content style={{ margin: '0 16px' }}>
          <Breadcrumb style={{ margin: '20px 0' }}>
            <Breadcrumb.Item>RPMS </Breadcrumb.Item>
          </Breadcrumb>
          <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
             <Routes>
        
              {(Number(selectedPage) === 1) &&  <Route path='/' element={<DashboardPage/>} />}
              {(Number(selectedPage) === 3) &&  <Route path='/' element={<FactsPage/>}></Route>}
              {(Number(selectedPage) === 6) && <Route path='/' element={<AdminUsersPage/>}/>}
              {(Number(selectedPage) === 5) && <Route path='/' element={<UsersPage/>} /> }
              {(Number(selectedPage) === 11) && <Route path='/' element={<LabelsPage/>} />}
              {(Number(selectedPage) === 4) && <Route path='/' element={<PlantsPage/>}/>}
             
            </Routes>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>RPMS v1.0 @2024</Footer>
      </Layout>
    </Layout>)}

    {(!loggedIn || !authenticated) && <LoginPage></LoginPage>}
    
   
    
    </BrowserRouter>
  );
}

export default App;
