import { Button, Table, Modal, Form, Space, Select, SelectProps, Alert, Tag, Skeleton, Spin, Radio } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { notify_success, toastStyle } from '../pages/UsersPage';
import { RequiredMark } from 'antd/es/form/Form';
import { FileExcelOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { listFacts } from '../actions/factsActions';
import { selectUserDetails } from '../features/auth/authSlice';
import * as XLSX from 'xlsx';
import { API_URL, AUTH_URL, DATA_URL } from '../constants';
import { TfiReload } from 'react-icons/tfi';
import { notify } from '../pages/LoginPage';
import { IPlant } from './ClientUsersTable';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { io } from 'socket.io-client';
const socket = io(AUTH_URL);

const { RangePicker } = DatePicker




        //Interfaces
export interface IFact {
  statement: string,
  meta_data: string,
  ass_medical_tasks: string,
  possible_decisions: string,
  labels: string[]

}

interface ICurator {
  title: string,
  surname: string,
  other_names: string,
  active: boolean

}
const options: SelectProps['options'] = [];

type EventValue<T> = T | null;

const DataTable = () => {
    const dispatch = useDispatch()
    const facts = useSelector((state: any) => state.facts);
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>('optional')

    //variable/states
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [factLoaded, setFactLoaded] = useState(false);
    const [factData, setFactData] = useState<IFact | null >()
    const [editable, setEditable] = useState(true);
    const [statement, setStatement] = useState('')
    const [metaData, setMetaData] = useState('')
    const [assMedicTasks, setAssMedicTasks] = useState('')
    const [possibleDecisions, setPossibleDecisions] = useState('')
    const [curated, setCurated] = useState(false)
    const [curator, setCurator] = useState<ICurator>({
      title: '',
      surname: '',
      other_names: '',
      active: false
    })

    const [selectOptions, setSelectOptions]= useState<string[]>([])
    const [labels, setLabels] = useState([])
    const [currentDoc, setCurrentDoc] = useState('')
    const [fact, setFact] = useState<IFact>({
      statement: '',
      meta_data: '',
      ass_medical_tasks: '',
      possible_decisions: '',
      labels: []

    })
    const [plants, setPlants]= useState<IPlant[]>()
    const [dateRange, setDateRange]= useState<string[]>(['2024-01-01', '2024-12-30'])
      // Convert string array to moment array
  const momentArray: Moment[] = dateRange.map(date => moment(date));
  type EventValue<T> = T | null
  // Convert moment array to Dayjs array
  const dayjsArray: Dayjs[] = momentArray.map(date => dayjs(date.toDate()));
      const valueArray: [EventValue<Dayjs>, EventValue<Dayjs>] = [
    dayjsArray[0] || undefined,
    dayjsArray[1] || undefined,
  ];


   const user_details = useSelector(selectUserDetails)
  
    const isCurator = user_details && user_details?.role === 'curator'
    const user_plants = user_details?.plant
    const [domain, setDomain] = useState<string | undefined>( undefined)

    const commonPlants = user_plants?.map(id => {
    const matchingObject = plants?.find(obj => obj._id === id);
        return matchingObject ? { _id: matchingObject._id, name: matchingObject.name } : null;
      }).filter(Boolean);

    const handleDomainChange = (value: string)=>{
        setDomain(value)
    }

    const handleChange = (value: string[]) => {
      setSelectOptions(value)
      setFact({ ...fact, labels: selectOptions });
      
    };

 const columns = [
    {
    title: 'No.',
    dataIndex: 'count',
    render: (text:any, record:any, index:any) => index + 1,
  },
  {
    title: 'Plant Name',
    dataIndex: 'plant_id',
    key: 'plant_id',
  },
  {
    title: 'Battery voltage',
    dataIndex: 'battery_voltage',
    key: 'battery_voltage',
  },
  {
    title: 'Ultrasonic flow rate',
    dataIndex: 'ultrasonic_flow_rate',
    key: 'ultrasonic_flow_rate',
  },
   {
    title: 'Date & time',
    dataIndex: 'date_of_creation',
    key: 'date_of_creation',
  },

];

    
    const clearFactData = ()=> setFactData(null)

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    clearFactData()
  };

   const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
    setRequiredMarkType(requiredMarkValue);
    };

  const handleCancel = () => {
    setIsModalOpen(false);
    clearFactData()
  };


   const handleSaveChanges = async()=>{
    const newFact = {
      statement: statement,
      meta_data:  metaData,
      ass_medical_tasks: assMedicTasks,
      possible_decisions: possibleDecisions,

    }

    const edit_res = await axios.patch(`${API_URL}/staff/data/facts/${currentDoc}`, newFact, {withCredentials: true})
    if(edit_res.data.success){
      notify_success("Record has been curated!", toastStyle)
  
    }
    else{
      notify_success(edit_res.data.message, toastStyle)
    }
  }

   const handleEditSubmission =()=>{
    setEditable(!editable)
  }

   const exportToExcel = () => {
      const fileName = 'rpms_data.xlsx';
      const table = document.getElementById('rpms-data-table');
      const clonedTable = table?.cloneNode(true) as HTMLTableElement;
      const excludedColumnIndices = [7];
      clonedTable.querySelectorAll('tr').forEach(row => {
        excludedColumnIndices.forEach(index => {
          row.deleteCell(index);
        });
      });

      const worksheet = XLSX.utils.table_to_sheet(clonedTable);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, fileName);
    };

        
  const getPlantNames = async()=> {
      
    const response = await axios.get(`${API_URL}/plant-names`)
    if(response.data.success){
        setPlants(response.data.plants)
    }else{
      notify('There are no plants in the database', toastStyle)
    }
    } 

  useEffect(()=>{

    dispatch(listFacts(domain, dateRange[0], dateRange[1]) as any)
    getPlantNames()

  }, [dispatch, domain, dateRange])

  useEffect(() => {
        socket.on('dataInserted', (data) => {
          if(user_plants?.includes(data.plant_id)){
            dispatch(listFacts(domain, dateRange[0], dateRange[1]) as any)
          }
        
 
        });

        return () => {
            socket.off('dataInserted');
        };
    }, []);

  return (
    <>
    {facts.facts.success? 
    <>

    <Row className=' justify-content-between align-items-center py-0 g-0'>
            <Col className='text-start py-2'>
               <Radio.Group defaultValue={domain} buttonStyle="solid" size='middle' onChange={((e)=> handleDomainChange(e.target.value))}>
                <Radio.Button className='rounded-0' value={undefined}>ALL</Radio.Button>
                {commonPlants && 
                commonPlants.map(plant=>{
                  return <Radio.Button className='rounded-0' value={plant?._id}>{plant?.name}</Radio.Button>
                })
                }
              </Radio.Group>
        </Col>
    

         <Col className='text-end py-2'>
            <RangePicker onChange={(date, dateString)=> setDateRange(dateString)} className='rounded-0 mx-2'
             value={valueArray}
             />
            <Button className='rounded-0 mx-1' onClick={exportToExcel}> <FileExcelOutlined /> <span>Download Report</span></Button>
            <Button onClick={ ()=>dispatch(listFacts(domain, dateRange[0], dateRange[1]) as any)} className='rounded-0' type="dashed"> <TfiReload/> <span className='mx-1'>Reload</span></Button>
         </Col>
            
        </Row>
    
     
     <Table className='rounded-0' id='rpms-data-table' bordered dataSource={facts.facts.data} columns={columns}  />
      
      </> : 
        <Spin style={{paddingTop: 100}} tip="Loading data..." size="large">
        <div className="content" />
      </Spin>

    }
           {(factData && factLoaded) && 
         <Modal width={1000} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
         footer={[
            isCurator && 
            (<Button key="custom" onClick={handleEditSubmission}>
              {editable  ? "Curate" : "Cancel curation"}
            </Button>),
            !editable && (
              <Button type="primary" onClick={handleSaveChanges}>
                Save Changes
              </Button>
            ),
            <Button key="cancel" onClick={handleCancel}>
              Exit
            </Button>
          ]}
         
         >
        <Card>
            <Card.Header>
                <Row className='justify-between'>
                    <Col className='text-uppercase fw-bold'>
                    Submission Preview
                    </Col>
                    <Col className='flex justify-content-end'>
                    <span style={{fontSize: '13px', padding: '4px 10px', textTransform: 'uppercase', color: "#000"}}>Curation Status:</span>
                    <Tag style={{fontSize: '12px', padding: '2px 10px', textTransform: 'uppercase'}}bordered={false} color={curated ? 'success' : 'magenta'}>
                    {curated ? "Curated" : "Pending"}</Tag>
                    
                    </Col>
                    {curated && <Col className='flex justify-content-end'>
                    <span style={{fontSize: '13px', padding: '4px 10px', textTransform: 'uppercase'}}>Curated By:</span>
                    <Tag style={{fontSize: '12px', padding: '2px 10px', textTransform: 'uppercase'}}bordered={false} color="blue">
                    {curator.surname + ' ' + curator.other_names}</Tag>
                    
                    </Col>}
                </Row>
                
            </Card.Header>
            {!editable && 
             <Alert style={{ margin: "15px 10px"}}
                  message="You are now in edit mode. Please be sure of the changes to make."
                  type="warning"
                  showIcon
                />}
            <Card.Body>
                <Row>

           <Form
      form={form}
      layout="vertical"
      initialValues={{ requiredMarkValue: requiredMark }}
      onValuesChange={onRequiredTypeChange}
      requiredMark={requiredMark}
    >
      <Form.Item label="Statement" required tooltip="Provide the fact here">

          <TextArea showCount maxLength={100} value={fact.statement}
            onChange={(e) => {
              setFact({ ...fact, statement: e.target.value })
              setStatement(e.target.value)
            
            }}
             readOnly={editable}

            />
      </Form.Item>
     
        <Form.Item label="Meta data" required tooltip={{ title: 'Provide relevant data this this fact', icon: <InfoCircleOutlined /> }}>

          <TextArea showCount maxLength={100}
          value={fact.meta_data}
            onChange={(e) => {
              setFact({ ...fact, meta_data: e.target.value })
              setMetaData(e.target.value)
            
            }}
             readOnly={editable}
          />
          
      </Form.Item>
       <Form.Item label="Associated medical tasks" required tooltip={{ title: 'Provide medical tasks associated this this fact', icon: <InfoCircleOutlined /> }}>

          <TextArea showCount maxLength={100}
            value={fact.ass_medical_tasks}
            onChange={(e) => {setFact({ ...fact, ass_medical_tasks: e.target.value });
            setAssMedicTasks(e.target.value)
          }}
             readOnly={editable}
            />
          
      </Form.Item>
   <Form.Item label="Possible decisions" required tooltip={{ title: 'Provide possible decisions for this fact', icon: <InfoCircleOutlined /> }}>

          <TextArea showCount maxLength={100}
           readOnly={editable}
          value={fact.possible_decisions}
            onChange={(e) => {
              setFact({ ...fact, possible_decisions: e.target.value })
              setPossibleDecisions(e.target.value)
            }}
          
          />
          
      </Form.Item>
      <Form.Item label="Labels" required tooltip="This is a required field">
        <Space style={{ width: '100%' }} direction="vertical">
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Select labels"
            disabled={editable}
            value={labels}
            options={options}
            onChange={handleChange}
            
          />
       </Space>
      </Form.Item>
    </Form>
                </Row>

            </Card.Body>
        </Card>
      </Modal>
        }
    </>
  )
}

export default DataTable
