import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import { Card, Col, Row } from 'react-bootstrap';

interface ChartProps {
  data: {
    [key: string]: {
      values: number[];
      timestamps: string[];
    }
  }
}

const LineChart: React.FC<ChartProps> = ({ data }) => {
  const chartRefs = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    chartRefs.current = Array.from({ length: 3 }, () => null);
  }, []);

  useEffect(() => {
    if(data){
       Object.keys(data).forEach((paramKey, index) => {
      if (chartRefs.current[index]) {
        const chartOptions: Highcharts.Options = {
          chart: {
            type: 'line',
            zooming: {
                type: 'x'
            }
          },
          title: {
          text: `${paramKey.replace(/_/g, ' ').toUpperCase()}, Avg.`, // Replace all underscores with a space
        },
          xAxis: {
            categories: data[paramKey].timestamps,
            title: {
              text: 'Timestamp',
            },
          },
          yAxis: {
            title: {
              text: 'Value',
            },
          },
          series: [
            {
              type: 'line',
              name: paramKey.replace('_', ' ').toUpperCase(),
              data: data[paramKey].values,
            },
          ],
          credits: {
            enabled: false
          }
        };

        Highcharts.chart(chartRefs.current[index]!, chartOptions); // Use non-null assertion operator
      }
    });
    }
   
  }, [data]);

  return (
    <Row>
      {
        data &&
        <>
        {Object.keys(data).map((paramKey, index) => (
        <Col md={6}>
        
        <Card className='rounded-0 my-2' key={paramKey} ref={(el:any) => (chartRefs.current[index] = el)} />
        </Col>
      ))}
        </>
      }
      
    </Row>
  );
};

export default LineChart;