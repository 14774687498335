import React, { useEffect, useState } from 'react'
import {Col,Row } from 'react-bootstrap'
import { Radio, Button } from 'antd'
import withAuth from '../components/authHOC'
import LineChart from '../components/LineCharts'
import { TfiReload } from 'react-icons/tfi'
import { getAnalytics } from '../actions/analyticsActions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { notify, toastStyle } from './LoginPage'
import { IPlant } from '../components/ClientUsersTable'
import { selectUserDetails } from '../features/auth/authSlice'
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment'
import dayjs, { Dayjs } from 'dayjs';
import { FileExcelOutlined } from '@ant-design/icons'
import { io } from 'socket.io-client'
import { API_SOCKET, AUTH_URL } from '../constants'
const socket = io(API_SOCKET);

const { RangePicker } = DatePicker;

type EventValue<T> = T | null;

const DashboardPage = () => {
    const [plants, setPlants]= useState<IPlant[]>()

  const analytics = useSelector((state: any) => state.analytics)
  const dispatch = useDispatch()
  const user_details = useSelector(selectUserDetails)
  const user_plants = user_details?.plant
  const [plant, setPlant] = useState<string | undefined>(user_plants ? user_plants[0] : undefined)

  const [dateRange, setDateRange]= useState<string[]>(['2024-01-01', '2024-12-30'])
      // Convert string array to moment array
  const momentArray: Moment[] = dateRange.map(date => moment(date))

  // Convert moment array to Dayjs array
  const dayjsArray: Dayjs[] = momentArray.map(date => dayjs(date.toDate()));
    const valueArray: [EventValue<Dayjs>, EventValue<Dayjs>] = [
    dayjsArray[0] || undefined,
    dayjsArray[1] || undefined,
  ];


  //merge plants with user plants
  const commonPlants = user_plants?.map(id => {
  const matchingObject = plants?.find(obj => obj._id === id);
      return matchingObject ? { _id: matchingObject._id, name: matchingObject.name } : null;
    }).filter(Boolean);


  const handlePlantChange = (plant_id: string)=>{
    setPlant(plant_id)
  }

  const getPlantNames = async()=> {
    
  const response = await axios.get(`${AUTH_URL}/plant-names`)
  if(response.data.success){
      setPlants(response.data.plants)
  }else{
    notify('There are no plants in the database', toastStyle)
  }
  } 

const printReport = ()=>{
  window.print()
}

useEffect(()=>{
  dispatch(getAnalytics(plant, dateRange[0], dateRange[1]) as any)
  getPlantNames()
}, [dispatch, plant, dateRange])

  useEffect(() => {
        socket.on('dataInserted', (data) => {
          console.log(data.plant_id)
          console.log(plant)

          if(user_plants?.includes(data.plant_id) && plant === data.plant_id){
            dispatch(getAnalytics(data.plant_id, dateRange[0], dateRange[1]) as any)
          }
        });

        return () => {
            socket.off('dataInserted');
        };
    }, []);


  return (
    <>
    <Row className=' justify-content-between align-items-center py-0 g-0'>
            <Col className='text-start py-2'>
               <Radio.Group defaultValue={plant} buttonStyle="solid" size='middle' onChange={((e)=> handlePlantChange(e.target.value))}>
                {commonPlants && 
                commonPlants.map(plant=>{
                  return <Radio.Button className='rounded-0' value={plant?._id}>{plant?.name}</Radio.Button>
                })
                }
               
              </Radio.Group>
        </Col>
    

         <Col className='text-end py-2'>
         <RangePicker onChange={(date, dateString)=> setDateRange(dateString)} className='rounded-0 mx-2'
             value={valueArray}
             />
              <Button className='rounded-0 mx-1' onClick={printReport}> <FileExcelOutlined /> <span>Print Report</span></Button>
         <Button onClick={()=>dispatch(getAnalytics(plant, dateRange[0], dateRange[1]) as any)} className='rounded-0' type="dashed"> <TfiReload/> <span className='mx-1'>Reload</span></Button>
         </Col>
            
        </Row>
    {analytics && 
    
    <LineChart data={analytics.data.data}></LineChart>
    }
  
    </>
  )
}

export default withAuth (DashboardPage, ['admin','user'])
